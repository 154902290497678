/* global window, document */

const nav = document.querySelector('nav');
const darks = document.querySelectorAll('.dark');

let pos = 0;
let ticking = false;

function checkNav(scrollY) {
  for (let i = 0; i < darks.length; i += 1) {
    if (
      scrollY + nav.offsetHeight >= darks[i].offsetTop
      && scrollY + nav.offsetHeight <= darks[i].offsetTop + darks[i].offsetHeight
    ) {
      nav.classList.add('dark');
      break;
    } else {
      nav.classList.remove('dark');
    }
  }
}

window.addEventListener('load', () => {
  window.addEventListener('scroll', (e) => {
    pos = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(() => {
        checkNav(pos);
        ticking = false;
      });
    }

    ticking = true;
  });

  checkNav(window.scrollY);
});

const burger = document.querySelector('.burger');
const mobileNav = document.querySelector('.mobile');

burger.addEventListener('click', () => {
  mobileNav.classList.toggle('show');
});
